.wrapperPayment{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.title{
  font-size: 10px;
  font-weight: 600;
}
.value{
  font-size: 12px;
}